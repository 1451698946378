import { CommonModule } from '@angular/common'
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewEncapsulation } from '@angular/core'
import { AbstractControl, FormControl, Validators } from '@angular/forms'
import {
  ButtonComponent,
  ButtonDisplayType,
  DestroySubscribers,
  IconComponent,
  OnClickOutsideDirective,
} from '@ftr/foundation'
import { Observable, distinctUntilChanged, filter, take, takeUntil } from 'rxjs'
import { ValidationErrorHintDirective } from '../../directives/validation-error-hint'
import { SelectItem } from './select-item'

let nextId = 0
/**
 * A select menu component similar to the ftr-select-search component but without the input
 */
@Component({
  selector: 'ftr-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ButtonComponent, CommonModule, IconComponent, OnClickOutsideDirective, ValidationErrorHintDirective],
})
export class SelectComponent<TValue> extends DestroySubscribers implements OnInit {
  /**
   * The control that contains this components value
   */
  @Input() control: AbstractControl
  /**
   * The list of things the user needs to select one of
   */
  @Input() items$: Observable<SelectItem<TValue>[]>
  /**
   * The default value of the element
   */
  @Input() defaultValue?: TValue

  @Input() equals: (a: TValue | undefined | null, b: TValue | undefined | null) => boolean = (a, b) => a === b
  /**
   * A label for this control
   */
  @Input() label: string
  /**
   * A label for the singular version
   */
  @Input() singular: string
  /**
   * Whether the control is enabled and can be clicked
   */
  @Input() enabled = true
  /**
   * Puts the component in a highlighted state with a larger border when true
   */
  @Input() highlightError?: Observable<boolean>
  /**
   * Is the input required or optional (default optional)
   * Use this instead of setting Validators.required on the external control
   * @type {boolean}
   */
  @Input() required = false

  /**
   * If the selected value needs to look different to what is in the drop down,
   * create a formatter function that receives the value and returns a new value
   */
  @Input() selectedItemFormatter?: Function
  /**
   * Hide the label that is shown above the input.
   * @type {boolean}
   */
  @Input() hideLabel = false
  /**
   * This is a temporary setting to enable select in the search-bar component
   * To be removed as a part of FTRX-5864
   * @type {boolean}
   */
  @Input() searchType = false
  /**
   * Whether submission of the form this component is part of has been attempted. When submission is attempted, the
   * underlying validation control (i.e.: ValidationHint) may choose to display validation states for fields which have
   * not yet been touched.
   */
  @Input() submitAttempted = false
  /**
   * Subscribe your component to this event to get notified when the array of items on this select changes
   */
  @Output() onItemsChange = new EventEmitter<SelectItem<TValue>[] | undefined>()
  /**
   * Subscribe your component to this event to get notified when the selection changes
   */
  @Output() onSelectionChange = new EventEmitter<TValue>()

  @Input() id = `ftr-select-${nextId++}`
  /**
   * Is the menu opened
   */
  selectedLabel: string
  isOpen = false

  readonly buttonDisplayType = ButtonDisplayType

  /**
   * The internal control that drives this component
   */
  internalControl: FormControl<TValue | null>

  private items: SelectItem<TValue>[] = []

  ngOnInit(): void {
    const initialValue = this.control.value
    const validators = []
    if (this.required) {
      validators.push(Validators.required)
    }

    // Update the external control validators
    this.control.setValidators(validators)
    this.internalControl = new FormControl(initialValue, validators)

    this.items$.pipe(takeUntil(this.finalize)).subscribe(items => {
      this.items = items
      this.setDefaultValue(items)
      this.onItemsChange.emit(items)
    })

    this.control.valueChanges
      .pipe(
        filter(v => !!v),
        takeUntil(this.finalize),
        distinctUntilChanged(),
      )
      .subscribe(change => {
        this.setValue(change)
      })
  }

  /**
   * Set the value to the control so it can be accessed by the consumer
   */
  setValue(value: TValue): void {
    this.internalControl.setValue(value)
    this.setSelectedLabel()
    this.onSelectionChange.emit(value)
    this.updateExternalControl(value)
    this.close()
  }

  getActiveDescendant(items: SelectItem<TValue>[]): string | null {
    return `${this.id}-item-${items.findIndex(i => this.equals(i.value, this.control.value))}`
  }

  /**
   * Close the drop down and mark the element touched
   */
  close(): void {
    this.isOpen = false
    this.internalControl.markAsTouched({ onlySelf: true })
  }

  /**
   * Open the drop down
   */
  open(): void {
    this.isOpen = true
  }

  @HostListener('keydown.Space', ['$event'])
  openDropdown(event: KeyboardEvent): void {
    event.preventDefault()
    this.open()
  }

  @HostListener('keydown.Tab', ['$event'])
  @HostListener('keydown.Shift.Tab', ['$event'])
  @HostListener('keydown.Escape', ['$event'])
  closeDropdown(): void {
    this.close()
  }

  @HostListener('keydown.ArrowDown', ['$event'])
  selectNextOption(event: KeyboardEvent): void {
    event.preventDefault()
    let nextOption: Element | undefined
    const currentOption = this.getCurrentOption()
    if (currentOption) {
      currentOption.classList.remove('select__item--hover')
      nextOption = currentOption?.nextElementSibling ? currentOption.nextElementSibling : this.getFirstOption()
    } else {
      nextOption = this.getFirstOption()
    }
    nextOption?.classList.add('select__item--hover')
    nextOption?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  @HostListener('keydown.ArrowUp', ['$event'])
  selectPreviousOption(event: KeyboardEvent): void {
    event.preventDefault()
    let previousOption: Element | undefined
    const currentOption = this.getCurrentOption()
    if (currentOption) {
      previousOption = currentOption?.previousElementSibling
        ? currentOption.previousElementSibling
        : this.getLastOption()
      currentOption.classList.remove('select__item--hover')
    } else {
      previousOption = this.getLastOption()
    }
    previousOption?.classList.add('select__item--hover')
    previousOption?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
  }

  @HostListener('keydown.Enter', ['$event'])
  selectCurrentOption(event: KeyboardEvent): void {
    event.preventDefault()
    const currentOption = this.getCurrentOption()
    if (currentOption) {
      const optionValue = this.items.find(o => o.key.trim() === currentOption.innerHTML.trim())?.value
      if (optionValue) {
        this.setValue(optionValue)
      }
    }
  }

  /**
   * Set the default selected value. If that value doesn't exist in the current list,
   * set the value to the first entry
   */
  private setDefaultValue(items: SelectItem<TValue>[]): void {
    if (!items.find(i => this.equals(i.value, this.internalControl.value))) {
      const validItem = items.some(item => this.equals(item.value, this.defaultValue))
      if (validItem && this.defaultValue) {
        this.internalControl.setValue(this.defaultValue)
      } else if (items.length) {
        this.internalControl.setValue(items[0].value)
      } else {
        this.internalControl.setValue(null)
      }
    }

    this.updateExternalControl(this.internalControl.value)
    this.setSelectedLabel()
  }

  /**
   * Set the label used to display what is selected.
   * This finds the key from the list of items, and optionally passes it through a formatter
   * if you want an option's value to display differently in the label than in the dropdown.
   */
  private setSelectedLabel(): void {
    if (this.selectedItemFormatter) {
      const label = this.selectedItemFormatter(this.internalControl.value)
      if (label !== null) {
        this.selectedLabel = label
        return
      }
    }

    this.items$.pipe(take(1)).subscribe(items => {
      const label = items.find(item => this.equals(item.value, this.internalControl.value))
      this.selectedLabel = label ? label.key : ''
    })
  }

  /**
   * Update the external control and its validity, ONLY if its different.
   * E.G. do not update the control if its already that value, otherwise we may get into a loop.
   * @param value
   */
  private updateExternalControl(value: TValue | null): void {
    if (!this.equals(this.control.value, value)) {
      this.control.setValue(value)
      this.control.markAsTouched({ onlySelf: true })
      this.control.updateValueAndValidity()
    }
  }
  // When two select inputs are used on the same page
  // one must set the Id input to distinguish between them
  private getCurrentOption(): Element | undefined {
    return (
      document.querySelector<Element>(`div[id^='${this.id}'] div.select__item--hover`) ??
      document.querySelector<Element>(`div[id^='${this.id}'] div.select__item--selected`) ??
      undefined
    )
  }

  private getFirstOption(): Element | undefined {
    const options = Array.from(document.querySelectorAll<Element>(`div[id^='${this.id}'] div.select__item`))
    return options.at(0)
  }

  private getLastOption(): Element | undefined {
    const options = Array.from(document.querySelectorAll<Element>(`div[id^='${this.id}'] div.select__item`))
    return options.at(-1)
  }
}
