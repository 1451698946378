/* eslint-disable complexity */
/* eslint-disable max-lines */
import { Inject, Injectable } from '@angular/core'
import {
  GetAudioSegmentHearingSectionsQueryParams,
  GetHearingHearingSectionsQueryParams,
  GetRecordingHearingSectionsQueryParams,
  HearingSectionConflict,
} from '@ftr/annotations-contracts'
import { AnnotationsApiClient, AnnotationsApiClientFactory } from '@ftr/api-annotations'
import { AUTH_TOKEN_FETCHER, AuthTokenFetcher } from '@ftr/api-shared'
import { assertIsDefined } from '@ftr/contracts/shared/Assertions'
import { VocabularyTerms } from '@ftr/contracts/type/core'
import { Uuid, generateUuid } from '@ftr/contracts/type/shared'
import { LocalTimeRange } from '@ftr/contracts/type/shared/LocalTimeRange'
import { RealTimePlaybackKey, getPlaybackId } from '@ftr/data-realtime-playback'
import {
  ButtonColor,
  ConfirmationModalService,
  ModalType,
  ToastService,
  assertUnreachable,
  titleCase,
} from '@ftr/foundation'
import { CourtSystemRegionCache } from '@ftr/ui-court-system'
import { LoggingService } from '@ftr/ui-observability'
import { RealTimeLiveHearingsService, buildJoinHearingAnnotationsRoomRequest } from '@ftr/ui-user'
import { VocabularyTermsService } from '@ftr/ui-vocab'
import { LocalTime } from '@js-joda/core'
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  StateOperator,
  createSelector,
  ofActionDispatched,
} from '@ngxs/store'
import { patch } from '@ngxs/store/operators'
import { isEqual, memoize } from 'lodash-es'
import { Observable, filter, firstValueFrom, of, takeUntil, throttleTime } from 'rxjs'
import { RealTimeSttMarkerEditSource, parseStartEndTimes } from '../../types'
import {
  HEARING_SECTION_CREATION_OUT_OF_BOUNDS_MESSAGE,
  HearingAnnotationTimeValidation,
  HearingAnnotationTimeValidationReason,
  getEnclosingTimeRangeForStartTime,
  mapHearingTimeValidationReasonMessage,
  validateHearingSectionTimes,
} from '../../utils'
import {
  CreateHearingSectionCommand,
  DeleteHearingSectionCommand,
  FetchHearingSectionsCommand,
  ListenForHearingSectionUpdatesCommand,
  SetHearingSectionToOngoingCommand,
  StopListeningForHearingSectionUpdatesCommand,
  StopOngoingHearingForRecordingCommand,
  UpdateAnnotationBoundariesCommand,
  UpdateAnnotationsLiveLocalTimeCommand,
  UpdateHearingSectionCommand,
} from './annotations.commands'
import {
  HearingSectionCreatedEvent,
  HearingSectionDeletedEvent,
  HearingSectionUpdatedEvent,
} from './annotations.events'
import { AnnotationsStateModel, HearingSectionModel, RecordingAnnotationsModel } from './annotations.model'
import { HearingAnnotationConflictsModalComponent } from './hearing-annotation-conflicts-modal'

const defaultPlaybackAnnotationsState = (): RecordingAnnotationsModel => ({
  hearingSections: {},
  annotationBoundaries: [],
  liveLocalTime: undefined,
})

export const getDefaultAnnotationsState: () => AnnotationsStateModel = () => ({
  playbackAnnotations: {},
})

@State({
  name: 'annotations',
  defaults: getDefaultAnnotationsState(),
})
@Injectable()
export class AnnotationsState {
  constructor(
    private readonly annotationsApiClientFactory: AnnotationsApiClientFactory,
    @Inject(AUTH_TOKEN_FETCHER) private readonly authTokenFetcher: AuthTokenFetcher,
    private readonly courtSystemRegionCache: CourtSystemRegionCache,
    private readonly realTimeLiveHearingsService: RealTimeLiveHearingsService,
    private readonly actions: Actions,
    private readonly toastService: ToastService,
    private readonly logger: LoggingService,
    private readonly confirmationModalService: ConfirmationModalService,
    private readonly vocabService: VocabularyTermsService,
  ) {}

  @Selector()
  static state(state: AnnotationsStateModel): AnnotationsStateModel {
    return state
  }

  @Selector()
  static allPlaybackInstanceStates(state: AnnotationsStateModel): Record<Uuid, RecordingAnnotationsModel> {
    return state.playbackAnnotations
  }

  static readonly playbackAnnotations = memoize(
    (
      playbackKey: RealTimePlaybackKey,
    ): ((sourceStates: ReturnType<typeof AnnotationsState.allPlaybackInstanceStates>) => RecordingAnnotationsModel) => {
      return createSelector(
        [AnnotationsState.allPlaybackInstanceStates],
        (sourceStates: ReturnType<typeof AnnotationsState.allPlaybackInstanceStates>) =>
          sourceStates[getPlaybackId(playbackKey)] ?? defaultPlaybackAnnotationsState(),
      )
    },
    playbackKey => getPlaybackId(playbackKey),
  )

  @Action(FetchHearingSectionsCommand)
  async fetchHearingSectionsCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: FetchHearingSectionsCommand,
  ): Promise<void> {
    const annotationId = getPlaybackId(action.playbackKey)
    const client = await this.getApiClient(action.courtSystemId)

    let query
    switch (action.playbackKey.type) {
      case 'audio-segment':
        query = {
          audioSegmentId: action.playbackKey.audioSegmentId,
        } satisfies GetAudioSegmentHearingSectionsQueryParams
        break
      case 'hearing':
        query = { hearingId: action.playbackKey.hearingId } satisfies GetHearingHearingSectionsQueryParams
        break
      case 'recording':
        query = {
          courtSystemId: action.courtSystemId,
          recordingId: action.playbackKey.recordingId,
        } satisfies GetRecordingHearingSectionsQueryParams
        break
      default:
        assertUnreachable(action.playbackKey)
    }

    const hearingsResult = await client.hearingSections.getHearingSections({ query })
    if (hearingsResult.status === 200) {
      const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)
      const existingHearings = playbackAnnotations.hearingSections
      const newHearingSections = hearingsResult.body.reduce<Record<Uuid, HearingSectionModel>>(
        (acc, hearingSection) => {
          acc[hearingSection.id] = {
            id: hearingSection.id,
            recordingId: hearingSection.recordingId,
            courtSystemId: hearingSection.courtSystemId,
            caseReference: hearingSection.caseReference ?? undefined,
            title: hearingSection.title ?? undefined,
            startTime: hearingSection.startTime,
            endTime: hearingSection.endTime,
            status: existingHearings[hearingSection.id]?.status ?? 'loaded',
            hearingId: hearingSection.hearingId ?? undefined,
            showAsOngoing: isOngoing(playbackAnnotations.liveLocalTime, hearingSection),
            showSetToOngoingButton: shouldShowSetToOngoingButton(playbackAnnotations, hearingSection.id),
            autofocus: false,
          }
          return acc
        },
        {},
      )

      stateContext.setState(
        patch({
          playbackAnnotations: patch({
            [annotationId]: patch({ hearingSections: newHearingSections }),
          }),
        }),
      )
    }
  }

  @Action(CreateHearingSectionCommand)
  async createHearingSectionCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: CreateHearingSectionCommand,
  ): Promise<void> {
    const terms = await firstValueFrom(this.vocabService.observeTerms(action.courtSystemId))
    const annotationId = getPlaybackId(action.playbackKey)
    const client = await this.getApiClient(action.courtSystemId)
    // Annotations can only be accurate to the second
    const startTime = action.startTime.withNano(0)

    const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)

    const matchingAnnotationBoundary = getEnclosingTimeRangeForStartTime(
      startTime,
      playbackAnnotations.annotationBoundaries,
    )
    if (!matchingAnnotationBoundary) {
      this.toastService.error(HEARING_SECTION_CREATION_OUT_OF_BOUNDS_MESSAGE(terms))
      return
    }

    const nextHearingSection = Object.values(playbackAnnotations.hearingSections)
      .sort((a, b) => a.startTime.compareTo(b.startTime))
      .find(x => x.startTime.isAfter(startTime))

    const endTime = nextHearingSection
      ? LocalTime.ofSecondOfDay(
          Math.min(matchingAnnotationBoundary.end.toSecondOfDay(), nextHearingSection?.startTime.toSecondOfDay()),
        )
      : matchingAnnotationBoundary.end

    let createResult
    let acknowledgedConflicts: HearingSectionConflict[] = []
    let previousConflictResolutionToken: string | undefined = undefined
    do {
      try {
        assertIsDefined(action.playbackKey.recordingId, 'Expected RealTimePlaybackKey to include recordingId')
        createResult = await client.hearingSection.createHearingSection({
          query: { conflictResolutionToken: previousConflictResolutionToken },
          body: {
            courtSystemId: action.courtSystemId,
            recordingId: action.playbackKey.recordingId,
            startTime,
            endTime,
            title: action.title,
            caseReference: action.caseReference,
            recordingDate: action.recordingDate,
            locationId: action.locationId,
          },
        })
      } catch (error) {
        this.logger.error({ message: 'Failed to create hearing section', error })
        this.toastService.error(`Failed to create ${terms.hearing.singular}.`)

        return
      }

      if (createResult.status === 409 && createResult.body.type === 'HearingSectionConflicts') {
        const hasConflictResolutionTokenExpired =
          previousConflictResolutionToken !== undefined &&
          previousConflictResolutionToken !== createResult.body.conflictResolutionToken
        previousConflictResolutionToken = createResult.body.conflictResolutionToken
        const shouldAcknowledgeConflicts = await this.shouldAcknowledgeConflicts(
          createResult.body.conflicts,
          playbackAnnotations,
          hasConflictResolutionTokenExpired,
          action.courtSystemId,
        )
        if (!shouldAcknowledgeConflicts) {
          return
        }

        acknowledgedConflicts = createResult.body.conflicts
      } else if (createResult.status === 201) {
        const { id, courtSystemId, recordingId, caseReference, title } = createResult.body
        const newHearingSection: HearingSectionModel = {
          id,
          recordingId,
          courtSystemId,
          startTime,
          endTime,
          caseReference: caseReference ?? undefined,
          title: title ?? undefined,
          status: 'saved',
          hearingId: undefined,
          showAsOngoing: isOngoing(playbackAnnotations.liveLocalTime, { startTime, endTime }),
          showSetToOngoingButton: false,
          autofocus: caseReference === null && title === null,
        }
        stateContext.setState(patchHearingSection(annotationId, id, newHearingSection))

        // For each complete overlap that we have acknowledged we have caused a deletion
        acknowledgedConflicts
          .filter(conflict => conflict.type === 'complete-overlap')
          .forEach(x =>
            stateContext.dispatch(new HearingSectionDeletedEvent(action.playbackKey, x.hearingSectionId, 'conflict')),
          )

        stateContext.dispatch(
          new HearingSectionCreatedEvent(action.playbackKey, action.courtSystemId, id, action.triggerSource),
        )
      } else {
        this.logger.error({ message: 'Unexpected status code when creating hearing section', response: createResult })
        this.toastService.error(`Failed to create ${terms.hearing.singular}.`)
      }
    } while (createResult.status === 409 && createResult.body.type === 'HearingSectionConflicts')
  }

  @Action(UpdateHearingSectionCommand)
  async updateHearingSectionCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: UpdateHearingSectionCommand,
  ): Promise<void> {
    const annotationId = getPlaybackId(action.playbackKey)
    const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)
    const originalHearingSection = playbackAnnotations.hearingSections[action.hearingSectionId]
    const client = await this.getApiClient(originalHearingSection.courtSystemId)
    const terms = await firstValueFrom(this.vocabService.observeTerms(originalHearingSection.courtSystemId))

    const parseResult = parseStartEndTimes<HearingAnnotationTimeValidationReason>(action.hearingSection)
    if ('parseError' in parseResult) {
      this.handleHearingSectionErrors(
        parseResult.parseError,
        stateContext.setState,
        annotationId,
        action.hearingSectionId,
        terms,
        action.hearingSection.source,
      )
      return
    }
    const { startTime, endTime } = parseResult

    const updatedHearingSection: HearingSectionModel = {
      ...playbackAnnotations.hearingSections[action.hearingSectionId],
      startTime,
      endTime,
      caseReference: action.hearingSection.caseReference.trim() || undefined,
      title: action.hearingSection.title?.trim() || undefined,
      source: action.hearingSection.source,
    }

    // short-circuit if the updated hearing section is the same as the existing one
    const existingHearingSection = playbackAnnotations.hearingSections[action.hearingSectionId]
    if (
      existingHearingSection.startTime.equals(updatedHearingSection.startTime) &&
      existingHearingSection.endTime.equals(updatedHearingSection.endTime) &&
      existingHearingSection.caseReference === updatedHearingSection.caseReference &&
      existingHearingSection.title === updatedHearingSection.title
    ) {
      stateContext.setState(patchHearingSection(annotationId, action.hearingSectionId, { status: 'loaded' }))
      return
    }

    const timeValidationError = validateHearingSectionTimes(
      startTime,
      endTime,
      playbackAnnotations.annotationBoundaries,
      playbackAnnotations.liveLocalTime,
    )
    if (timeValidationError) {
      this.handleHearingSectionErrors(
        timeValidationError,
        stateContext.setState,
        annotationId,
        action.hearingSectionId,
        terms,
        action.hearingSection.source,
      )
      return
    }

    stateContext.setState(
      patchHearingSection(annotationId, action.hearingSectionId, {
        status: 'saving',
        source: action.hearingSection.source,
      }),
    )

    const hasCaseReferenceChanged =
      updatedHearingSection.caseReference !==
      playbackAnnotations.hearingSections[action.hearingSectionId]?.caseReference
    if (hasCaseReferenceChanged) {
      const caseTitleSuggestion = await this.getCaseTitleSuggestion(updatedHearingSection)
      updatedHearingSection.title = caseTitleSuggestion || updatedHearingSection.title
    }

    let updateResult
    let acknowledgedConflicts: HearingSectionConflict[] = []
    let previousConflictResolutionToken: string | undefined = undefined
    do {
      try {
        updateResult = await client.hearingSection.updateHearingSection({
          query: { conflictResolutionToken: previousConflictResolutionToken },
          body: {
            recordingId: updatedHearingSection.recordingId,
            id: updatedHearingSection.id,
            startTime: updatedHearingSection.startTime,
            endTime: updatedHearingSection.endTime,
            title: updatedHearingSection.title ?? null,
            caseReference: updatedHearingSection.caseReference ?? null,
            recordingDate: action.recordingDate,
            locationId: action.locationId,
          },
        })
      } catch (error) {
        this.logger.error({ message: 'Failed to update hearing section', error })
        stateContext.setState(
          patchHearingSection(annotationId, action.hearingSectionId, {
            status: { errorMessage: 'Failed to save.', showRetry: true, showReset: false },
            source: action.hearingSection.source,
          }),
        )
        return
      }
      if (updateResult.status === 200) {
        stateContext.setState(
          patchHearingSection(annotationId, action.hearingSectionId, {
            ...updatedHearingSection,
            status: 'saved',
            showAsOngoing: isOngoing(playbackAnnotations.liveLocalTime, updatedHearingSection),
            showSetToOngoingButton: shouldShowSetToOngoingButton(playbackAnnotations, action.hearingSectionId),
          }),
        )
        // For each complete overlap that we have acknowledged we have caused a deletion
        acknowledgedConflicts
          .filter(conflict => conflict.type === 'complete-overlap')
          .forEach(() =>
            stateContext.dispatch(
              new HearingSectionDeletedEvent(action.playbackKey, action.hearingSectionId, 'conflict'),
            ),
          )
        stateContext.dispatch(
          new HearingSectionUpdatedEvent(
            action.playbackKey,
            originalHearingSection.id,
            originalHearingSection,
            updatedHearingSection,
          ),
        )
      } else if (updateResult.status === 409 && updateResult.body.type === 'HearingSectionConflicts') {
        const hasConflictResolutionTokenExpired =
          previousConflictResolutionToken !== undefined &&
          previousConflictResolutionToken !== updateResult.body.conflictResolutionToken
        previousConflictResolutionToken = updateResult.body.conflictResolutionToken
        const shouldAcknowledgeConflicts = await this.shouldAcknowledgeConflicts(
          updateResult.body.conflicts,
          playbackAnnotations,
          hasConflictResolutionTokenExpired,
          originalHearingSection.courtSystemId,
        )
        if (!shouldAcknowledgeConflicts) {
          stateContext.setState(
            patchHearingSection(annotationId, updatedHearingSection.id, {
              forceResetFormKey: generateUuid(),
              status: 'loaded',
              source: action.hearingSection.source,
            }),
          )
          return
        }
        acknowledgedConflicts = updateResult.body.conflicts
      } else {
        this.logger.error({ message: 'Unexpected status code when updating hearing section', response: updateResult })
        const errorMessage =
          updateResult.body && 'error' in updateResult.body ? updateResult.body.error : 'Failed to save.'
        stateContext.setState(
          patchHearingSection(annotationId, action.hearingSectionId, {
            status: { errorMessage, showRetry: true, showReset: false },
            source: action.hearingSection.source,
          }),
        )
      }
    } while (updateResult.status === 409 && updateResult.body.type === 'HearingSectionConflicts')
  }

  private async getCaseTitleSuggestion({
    courtSystemId,
    recordingId,
    caseReference,
  }: HearingSectionModel): Promise<string> {
    if (!caseReference?.trim()) {
      return ''
    }

    const client = await this.getApiClient(courtSystemId)
    try {
      const suggestionResponse = await client.hearingSection.getSuggestionsForHearingSection({
        query: {
          courtSystemId,
          recordingId,
          caseReference,
        },
      })
      if (suggestionResponse.status === 200 && suggestionResponse.body.caseTitleSuggestion) {
        return suggestionResponse.body.caseTitleSuggestion
      } else {
        this.logger.error({ message: 'Failed to get case title suggestion', response: suggestionResponse })
      }
    } catch (error) {
      this.logger.error({ message: 'Unexpected status code when getting case title suggestion', error })
    }
    return ''
  }

  private handleHearingSectionErrors(
    validationResult: HearingAnnotationTimeValidation,
    setState: (val: StateOperator<AnnotationsStateModel> | AnnotationsStateModel) => void,
    annotationId: string,
    hearingSectionId: string,
    terms: VocabularyTerms,
    source?: RealTimeSttMarkerEditSource,
  ): void {
    setState(
      patchHearingSection(annotationId, hearingSectionId, {
        status: {
          errorMessage: mapHearingTimeValidationReasonMessage(validationResult, terms),
          fields: mapValidationReasonToFields(validationResult.reason),
          showRetry: false,
          showReset: true,
        },
        source,
      }),
    )
  }

  @Action(DeleteHearingSectionCommand)
  async deleteHearingSectionCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    { playbackKey, id, courtSystemId }: DeleteHearingSectionCommand,
  ): Promise<void> {
    const terms = await firstValueFrom(this.vocabService.observeTerms(courtSystemId))
    const originalState = stateContext.getState()
    const annotationId = getPlaybackId(playbackKey)
    const client = await this.getApiClient(courtSystemId)
    const hearingSectionId = id

    let deleteResult
    try {
      deleteResult = await client.hearingSection.deleteHearingSection({
        query: { hearingSectionId },
      })
    } catch (error) {
      this.logger.error({ message: 'Failed to delete hearing section', error })
      this.toastService.error(`Failed to delete ${terms.hearing.singular}.`)
      return
    }
    if (deleteResult.status === 204) {
      const newHearingSections = { ...originalState.playbackAnnotations[annotationId].hearingSections }
      delete newHearingSections[hearingSectionId]
      stateContext.setState(
        patch({
          playbackAnnotations: patch({
            [annotationId]: patch({
              hearingSections: newHearingSections,
            }),
          }),
        }),
      )
      stateContext.dispatch(new HearingSectionDeletedEvent(playbackKey, hearingSectionId, 'popover'))
    } else {
      this.toastService.error(`Failed to delete ${terms.hearing.singular}.`)
      this.logger.warn({ message: 'Unexpected status code when deleting hearing section', response: deleteResult })
    }
  }

  @Action(ListenForHearingSectionUpdatesCommand)
  async listenForHearingSectionUpdatesCommand(
    { dispatch }: StateContext<AnnotationsStateModel>,
    action: ListenForHearingSectionUpdatesCommand,
  ): Promise<void> {
    const auth = await this.authTokenFetcher.getToken()
    const region = await firstValueFrom(
      this.courtSystemRegionCache.getOrFetchCourtSystem(of(action.courtSystemId)),
    ).then(courtSystem => courtSystem.region)

    const joinHearingAnnotationsRoomRequest = buildJoinHearingAnnotationsRoomRequest(
      action.playbackKey,
      action.courtSystemId,
      region,
      auth,
    )
    if (!joinHearingAnnotationsRoomRequest) {
      return
    }
    this.realTimeLiveHearingsService
      .observeLiveHearingUpdates(joinHearingAnnotationsRoomRequest)
      .pipe(
        throttleTime(500),
        takeUntil(
          this.actions.pipe(
            ofActionDispatched(StopListeningForHearingSectionUpdatesCommand),
            filter(x => isEqual(x.playbackKey, action.playbackKey)),
          ),
        ),
      )
      .subscribe(() => {
        dispatch(new FetchHearingSectionsCommand(action.playbackKey, action.courtSystemId))
      })
  }

  @Action(UpdateAnnotationBoundariesCommand)
  updateAnnotationBoundariesCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: UpdateAnnotationBoundariesCommand,
  ): void {
    const annotationId = getPlaybackId(action.playbackKey)
    this.getOrInitializePlaybackAnnotations(stateContext, action)
    stateContext.setState(
      patch({
        playbackAnnotations: patch({
          [annotationId]: patch({
            annotationBoundaries: action.annotationBoundaries,
          }),
        }),
      }),
    )
  }

  @Action(UpdateAnnotationsLiveLocalTimeCommand)
  updateAnnotationsLiveLocalTimeCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: UpdateAnnotationsLiveLocalTimeCommand,
  ): void {
    const annotationId = getPlaybackId(action.playbackKey)
    const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)
    stateContext.setState(
      patch({
        playbackAnnotations: patch({
          [annotationId]: patch({
            liveLocalTime: action.liveLocalTime,
            hearingSections: Object.fromEntries(
              Object.entries(playbackAnnotations.hearingSections).map(([id, hearingSection]) => [
                id,
                {
                  ...hearingSection,
                  showAsOngoing: isOngoing(action.liveLocalTime, hearingSection),
                  showSetToOngoingButton: shouldShowSetToOngoingButton(playbackAnnotations, id),
                },
              ]),
            ),
          }),
        }),
      }),
    )
  }

  @Action(SetHearingSectionToOngoingCommand)
  async setHearingSectionToOngoingCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: SetHearingSectionToOngoingCommand,
  ): Promise<void> {
    const annotationId = getPlaybackId(action.playbackKey)
    const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)
    const { courtSystemId } = playbackAnnotations.hearingSections[action.hearingSectionId]
    const terms = await firstValueFrom(this.vocabService.observeTerms(courtSystemId))

    const { liveLocalTime } = playbackAnnotations
    if (!liveLocalTime) {
      // no such thing as 'ongoing' if we're not live
      return
    }

    const annotationBoundary = playbackAnnotations.annotationBoundaries.find(x => x.contains(liveLocalTime))
    if (!annotationBoundary) {
      // no such thing as 'ongoing' if we're not in a valid annotation boundary
      return
    }

    const hearingSection = playbackAnnotations.hearingSections[action.hearingSectionId]
    if (!hearingSection) {
      // well this just isn't good for anyone
      return
    }

    stateContext.setState(patchHearingSection(annotationId, action.hearingSectionId, { status: 'saving' }))

    const client = await this.getApiClient(hearingSection.courtSystemId)

    let updateResult
    try {
      updateResult = await client.hearingSection.updateHearingSection({
        body: {
          recordingId: hearingSection.recordingId,
          id: hearingSection.id,
          startTime: hearingSection.startTime,
          endTime: annotationBoundary.end,
          title: hearingSection.title ?? null,
          caseReference: hearingSection.caseReference ?? null,
          recordingDate: action.recordingDate,
          locationId: action.locationId,
        },
      })
    } catch (error) {
      this.logger.error({ message: 'Failed to set hearing section to ongoing', error })
      this.toastService.error(`Failed to set ${terms.hearing.singular} to ongoing.`)
      stateContext.setState(patchHearingSection(annotationId, action.hearingSectionId, { status: 'loaded' }))
      return
    }

    if (updateResult.status === 200) {
      stateContext.setState(patchHearingSection(annotationId, action.hearingSectionId, { status: 'saved' }))
    } else {
      this.logger.error({ message: 'Unexpected status code when creating hearing section', response: updateResult })
      this.toastService.error(`Failed to set ${terms.hearing.singular} to ongoing.`)
      stateContext.setState(patchHearingSection(annotationId, action.hearingSectionId, { status: 'loaded' }))
    }
  }

  @Action(StopOngoingHearingForRecordingCommand)
  stopOngoingHearingForRecordingCommand(
    stateContext: StateContext<AnnotationsStateModel>,
    action: StopOngoingHearingForRecordingCommand,
  ): Observable<void> {
    // find the ongoing hearing section since there can only be one
    const playbackAnnotations = this.getOrInitializePlaybackAnnotations(stateContext, action)
    const ongoingHearingSection = Object.values(playbackAnnotations.hearingSections).find(x => x.showAsOngoing)
    const liveLocalTime = playbackAnnotations.liveLocalTime

    if (!ongoingHearingSection || !liveLocalTime) {
      return of()
    }

    return stateContext.dispatch(
      new UpdateHearingSectionCommand(
        action.playbackKey,
        ongoingHearingSection.id,
        {
          ...ongoingHearingSection,
          caseReference: ongoingHearingSection.caseReference ?? '',
          title: ongoingHearingSection.title ?? '',
          startTime: ongoingHearingSection.startTime.toString(),
          endTime: liveLocalTime.toString(),
        },
        action.recordingDate,
        action.locationId,
      ),
    )
  }

  private async getApiClient(courtSystemId: Uuid): Promise<AnnotationsApiClient> {
    const { region } = await firstValueFrom(this.courtSystemRegionCache.getOrFetchCourtSystem(of(courtSystemId)))
    return this.annotationsApiClientFactory.build(region)
  }

  private getOrInitializePlaybackAnnotations(
    { getState, setState }: StateContext<AnnotationsStateModel>,
    { playbackKey }: { playbackKey: RealTimePlaybackKey },
  ): RecordingAnnotationsModel {
    const originalState = getState()
    const annotationId = getPlaybackId(playbackKey)
    let playbackAnnotations = originalState.playbackAnnotations[annotationId]
    if (!playbackAnnotations) {
      playbackAnnotations = defaultPlaybackAnnotationsState()
      setState(
        patch({
          playbackAnnotations: patch({
            [annotationId]: playbackAnnotations,
          }),
        }),
      )
    }
    return playbackAnnotations
  }

  private async shouldAcknowledgeConflicts(
    conflicts: HearingSectionConflict[],
    playbackAnnotations: RecordingAnnotationsModel,
    hasConflictResolutionTokenExpired: boolean,
    courtSystemId: Uuid,
  ): Promise<boolean> {
    const terms = await firstValueFrom(this.vocabService.observeTerms(courtSystemId))
    const completeOverlaps = conflicts
      .filter(conflict => conflict.type === 'complete-overlap')
      .map(conflict => playbackAnnotations.hearingSections[conflict.hearingSectionId])

    if (completeOverlaps.length === 0) {
      // we don't bother prompting the user if there are no complete overlaps (deletions)
      return true
    }

    const hearingTerm = completeOverlaps.length > 1 ? terms.hearing.plural : terms.hearing.singular
    return await this.confirmationModalService.confirm({
      modalType: ModalType.Error,
      title: `${titleCase(hearingTerm)} Conflict`,
      confirmText: `Delete ${hearingTerm}`,
      confirmButtonColor: ButtonColor.Danger,
      content: {
        type: HearingAnnotationConflictsModalComponent,
        inputs: {
          terms,
          hearingSections: completeOverlaps,
          hasPreviousConfirmationExpired: hasConflictResolutionTokenExpired,
        },
      },
    })
  }
}

function isOngoing(
  liveLocalTime: LocalTime | undefined,
  hearingSection: Pick<HearingSectionModel, 'startTime' | 'endTime'>,
): boolean {
  return liveLocalTime
    ? new LocalTimeRange(hearingSection.startTime, hearingSection.endTime).contains(liveLocalTime)
    : false
}

export function shouldShowSetToOngoingButton(
  { liveLocalTime, annotationBoundaries, hearingSections }: RecordingAnnotationsModel,
  hearingSectionId: Uuid,
): boolean {
  if (!liveLocalTime) {
    // ongoing only makes sense when the recording is live
    return false
  }

  const hearingSection = hearingSections[hearingSectionId]
  if (!hearingSection || isOngoing(liveLocalTime, hearingSection)) {
    // no point in showing the button if its already ongoing
    return false
  }

  // the hearing section must be the last in chronological order
  const lastHearingSection = Object.values(hearingSections)
    .sort((a, b) => a.startTime.compareTo(b.startTime))
    .pop()!
  if (lastHearingSection.id !== hearingSection.id) {
    return false
  }

  // the hearing section must be within the live annotation boundary
  const liveAnnotationBoundary = annotationBoundaries.find(x => x.contains(liveLocalTime))
  return liveAnnotationBoundary?.contains(hearingSection.startTime) ?? false
}

function mapValidationReasonToFields(
  validationResult: HearingAnnotationTimeValidationReason,
): (keyof HearingSectionModel)[] {
  const affectsAll = ['both-out-of-bounds', 'different-sessions', 'start-must-be-before-end', 'both-are-not-times']
  const startIsInvalid = ['start-is-out-of-bounds', 'start-is-not-a-time', ...affectsAll].includes(validationResult)
  const endIsInvalid = ['end-is-out-of-bounds', 'future-end-time', 'end-is-not-a-time', ...affectsAll].includes(
    validationResult,
  )
  const fields: (keyof HearingSectionModel)[] = []
  if (startIsInvalid) {
    fields.push('startTime')
  }
  if (endIsInvalid) {
    fields.push('endTime')
  }
  return fields
}

function patchHearingSection(
  annotationId: Uuid,
  hearingSectionId: Uuid,
  changes: Partial<HearingSectionModel>,
): StateOperator<AnnotationsStateModel> {
  return patch({
    playbackAnnotations: patch({
      [annotationId]: patch({
        hearingSections: patch({
          [hearingSectionId]: patch(changes),
        }),
      }),
    }),
  })
}
